import './TimeLine.css'

import React, {Component} from 'react'

import {connect} from "react-redux";
import {timerTick, timerStart, timerStop, timerChangeSpeed} from "../../Redux/actions";

import posed from 'react-pose';

const Widget = posed.section({
    visible: {
        y: 0,
        opacity: 1
    },
    hidden: {
        y:  20,
        opacity: 0
    }
});

class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        setTimeout(()=>this.setState({isVisible: true}), 1300)
    }

    render() {
        let startTime = this.props.startTime;
        let currentTime = this.props.currentTime;
        let endTime = this.props.endTime;
        let isPlaying = !!this.props.timerId;

        let total = endTime - startTime;
        let now = currentTime - startTime;
        let progressBorders = 0;
        if (this.progressTime && this.progressWrapper) {
            progressBorders = this.progressTime.offsetWidth / this.progressWrapper.offsetWidth * 100 / 2;
        }
        let progress = Math.max(Math.min(now / total * 100, 100 - progressBorders), progressBorders);

        let { isVisible } = this.state;
        return (
            <Widget className="timeline-panel" pose={isVisible ? "visible" : "hidden"}>
                <div className="timeline-wrapper">
                    <div className="timeline">
                        <div className="progress-wrapper" ref={ref => {
                            this.progressWrapper = ref
                        }}>
                            <div className="scale">
                                <b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/><b/>
                            </div>
                            <div className="progress" style={{transform: `translateX(${progress - 100}%)`}}>
                                <time ref={ref => {
                                    this.progressTime = ref
                                }}>
                                    {this._convertMinutesToTwelveHours(currentTime)}
                                </time>
                            </div>
                        </div>
                        <span className="start">{this._convertMinutesToTwelveHours(startTime)}</span>
                        <span className="end">{this._convertMinutesToTwelveHours(endTime)}</span>
                    </div>
                    <div className="playback-controls">
                        <div className="buttons-wrapper">
                            <button className="backward" aria-label="Backward" onClick={this.onBackwardClick}/>
                            <button className="play" aria-label="Play" onClick={this.onPlayClick} aria-pressed={isPlaying}/>
                            <button className="forward" aria-label="Forward" onClick={this.onForwardClick}/>
                        </div>
                        <p className="speed">{this.props.speed}× Speed</p>
                    </div>
                </div>
                <ul className="kpi-wrapper">
                    <li className="kpi-stops">
                        <p className="value">22.8</p>
                        <p className="label">Avg. Stops planned</p>
                    </li>
                    <li className="kpi-service">
                        <p className="value">5h 37min</p>
                        <p className="label">Avg. Service time</p>
                    </li>
                    <li className="kpi-driving">
                        <p className="value">3h 07min</p>
                        <p className="label">Avg. Driving time</p>
                    </li>
                    <li className="kpi-waiting">
                        <p className="value">0h 08min</p>
                        <p className="label">Avg. Waiting time</p>
                    </li>
                    <li className="kpi-break">
                        <p className="value">0h 45min</p>
                        <p className="label">Avg. Break time</p>
                    </li>
                </ul>
            </Widget>
        );
    }
    
    _convertMinutesToTwelveHours(currentTime) {
        let hours = Math.trunc(currentTime / 60),
            minutes = Math.trunc(currentTime % 60);
        return `${hours > 12 ? (hours - 12) : hours}:${minutes > 9 ? minutes : ("0" + minutes)} ${hours > 12 ? "PM" : "AM"}`
    }

    onPlayClick = () => {
        this.props.timerId ? this.props.timerStop() : this.props.timerStart()
    };

    onForwardClick = () => {
        if(this.props.speed >= 0.5 && this.props.speed < 3)
            this.props.timerChangeSpeed(0.5);
        else if (this.props.speed >= 0.1 && this.props.speed < 0.5)
            this.props.timerChangeSpeed(0.1)
    };

    onBackwardClick = () => {
        if(this.props.speed > 0.5)
            this.props.timerChangeSpeed(-0.5);
        else if (this.props.speed <= 0.5 && this.props.speed > 0.1)
            this.props.timerChangeSpeed(-0.1)
    }
}

function mapDispatchToProps(state) {
    return {
        ...state.timer
    }
}

export default connect(mapDispatchToProps, {timerStart, timerStop, timerTick, timerChangeSpeed})(TimeLine);
