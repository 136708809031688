import ActionType from '../actionTypes';
import store from "../Store";
import {timerReset, timerStart, timerTick} from "../actions";

const tickIntervalMs = 16;

const initialState = {
    startTime: 0,
    endTime: 9999,
    currentTime: 0,
    timerId: undefined,
    
    isTimerInitialized: false,
    speed: 0.2
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionType.TIMER_RESET: {
            return {
                ...state,
                currentTime: state.startTime
            }
        }
        case ActionType.TIMER_INIT: {
            return {
                ...state,
                isTimerInitialized: true,
                currentTime: action.startTime,
                startTime: action.startTime,
                endTime: action.endTime
            }
        }
        case ActionType.TIMER_START: {
            clearInterval(state.timerId);
            return {
                ...state,
                timerId: setInterval(function () {
                    if(store.getState().timer.currentTime > state.endTime) {
                        store.dispatch(timerReset());
                    } else {
                        store.dispatch(timerTick());
                    }
                }, tickIntervalMs)
            }
        }
        case ActionType.TIMER_STOP: {
            return {
                ...state,
                timerId: clearInterval(state.timerId)
            }
        }
        case ActionType.TIMER_TICK: {
            return {
                ...state,
                currentTime: state.currentTime + state.speed
            }
        }
        case ActionType.TIMER_CHANGE_SPEED: {
            clearInterval(state.timerId);
            return {
                ...state,
                speed: Number.parseFloat((state.speed + action.delta).toFixed(1)),
                timerId: setInterval(function () {
                    if(store.getState().timer.currentTime > state.endTime) {
                        store.dispatch(timerReset());
                    } else {
                        store.dispatch(timerTick());
                    }
                }, tickIntervalMs)
            }
        }
        default:
            return state;
    }
}