export default  {
    HIGHLIGHT_ROUTE: "HIGHLIGHT_ROUTE",
    
    MAP_STATE_CHANGED: "MAP_STATE_CHANGED",
    MAP_POINT_SELECTED: "MAP_POINT_SELECTED",
    
    FETCH_ROUTES_REQUEST: "FETCH_ROUTES_REQUEST",
    FETCH_ROUTES_SUCCESS: "FETCH_ROUTES_SUCCESS",
    FETCH_ROUTES_FAILURE: "FETCH_ROUTES_FAILURE",

    FETCH_VEHICLE_CAPACITIES_REQUEST: "FETCH_VEHICLE_CAPACITIES_REQUEST",
    FETCH_VEHICLE_CAPACITIES_SUCCESS: "FETCH_VEHICLE_CAPACITIES_SUCCESS",
    FETCH_VEHICLE_CAPACITIES_FAILURE: "FETCH_VEHICLE_CAPACITIES_FAILURE",
    
    TIMER_INIT: "TIMER_INIT",
    TIMER_TICK: "TIMER_TICK",
    TIMER_START: "TIMER_START",
    TIMER_STOP: "TIMER_STOP",
    TIMER_RESET: "TIMER_RESET",
    
    TIMER_CHANGE_SPEED: "TIMER_CHANGE_SPEED",
    SHOW_ROUTE_DETAILS: "SHOW_ROUTE_DETAILS",
    SHOW_OVERVIEW: "SHOW_OVERVIEW"
}
