import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter as Router} from "react-router-dom";

import store from "./Redux/Store";

window.mapboxgl = require('mapbox-gl');
window.mapboxgl.accessToken = 'pk.eyJ1IjoibjFjMHNoIiwiYSI6ImNqOTcxZmM2OTAya3AzMnJ3ZHhrMWx5cnUifQ.AgEyh9xOy31f-h5LksaYRQ';

require('echarts');
require('echarts-gl');

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);
