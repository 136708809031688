import './DetailsSideBar.css';

import React, {Component} from 'react';
import ReactEcharts from 'echarts-for-react';

import {connect} from "react-redux";

import logoIcon from './assets/logo.svg';
import backIcon from './assets/back.svg';
import {timerStop} from "../../Redux/actions";
import {Link, withRouter} from "react-router-dom";
import posed from "react-pose";

let widgetStyle = {height: '100%', width: '100%'};

const Container = posed.aside({
    open: {
        delayChidren: 1200,
        staggerChildren: 150
    },
    closed: {
        delay: 300
    }
});

const Widget = posed.section({
    open: { y: 0, opacity: 1 },
    closed: { y: 20, opacity: 0 }
});

class DetailsSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {
        setTimeout(()=>this.setState({isOpen: true}), 1000)
    }
    
    render() {
        const { isOpen } = this.state;
        return (
            <Container className="DetailsSideBar" pose={isOpen ? 'open' : 'closed'}>
                <header>
                    <img src={logoIcon} className="logo" alt={''}/>
                    <Link to="/" className="backLink"><img src={backIcon} alt="Back" className="backIcon"/></Link>
                </header>
                <Widget className="widget">
                    <ReactEcharts
                        style={widgetStyle}
                        option={this.getServicesOptions()}
                    />
                </Widget>
                <Widget className="multiWidget">
                    <section className="subWidget">
                        <ReactEcharts
                            style={widgetStyle}
                            option={this.getParamBarOptions("Weight Utilization")}
                        />
                    </section>
                    <section className="subWidget">
                        <ReactEcharts
                            style={widgetStyle}
                            option={this.getParamBarOptions("Pallets Utilization")}
                        />
                    </section>
                </Widget>
                <Widget className="multiWidget">
                    <section className="subWidget">
                        <ReactEcharts
                            style={widgetStyle}
                            option={this.getParamBarOptions("Loading Meter Utilization")}
                        />
                    </section>
                    <section className="subWidget">
                        <ReactEcharts
                            style={widgetStyle}
                            option={this.getParamBarOptions("Volume Utilization")}
                        />
                    </section>
                </Widget>
            </Container>
        );
    }


    getServicesOptions() {
        let vehicleId = parseInt(this.props.match.params.vehicleId);
        const completedColor = 'rgb(41, 164, 237)';
        const completedStyle = {
            barBorderRadius: [3, 3, 0, 0],
            color: completedColor,
            shadowBlur: 1,
            shadowColor: completedColor
        };
        const plannedColor = 'rgb(128, 128, 128)';
        const plannedStyle = {
            barBorderRadius: [3, 3, 0, 0],
            color: plannedColor,
            shadowBlur: 1,
            shadowColor: plannedColor
        };

        const startHour = Math.trunc(this.props.startTime / 60);
        const endHour = Math.trunc(this.props.endTime / 60);
        const currentHour = Math.trunc(this.props.currentTime / 60) - startHour;
        
        const plannedData = [];
        this.props.servicesByHourMap.forEach(value=>{
            let servicesCount = value.byVehicles.get(vehicleId);
            if(servicesCount) 
                plannedData.push(servicesCount)
        });
        
        const currentMinutes = this.props.currentTime % 60;
        const completedData = plannedData.map((e, index)=>index < currentHour ? e : index === currentHour ? Math.trunc(plannedData[currentHour] * currentMinutes / 60) : 0);
        
        let totalServices = plannedData.reduce((a,v)=>a+v);
        let completedServices = completedData.length > 0 ? completedData.reduce((val, acc)=>val+acc) : 0;

        return {
            title: {
                left: 'center',
                bottom: 5,
                text: `{completed|${completedServices}}{planned| / ${totalServices}}`,
                textStyle: {
                    rich: {
                        completed: {
                            color: completedColor,
                            fontSize: 18,
                            fontWeight: 'bold',
                            fontFamily: 'Arial'
                        },
                        planned: {
                            color: plannedColor,
                            fontSize: 18,
                            fontWeight: 'bold',
                            fontFamily: 'Arial'
                        }
                    }
                },
                itemGap: 1,
                subtext: "Completed services",
                subtextStyle: {
                    color: '#ddd',
                    fontSize: 18,
                    fontFamily: 'Arial'
                }
            },

            animation: false,
            backgroundColor: 'rgb(30, 29, 33)',

            xAxis: [{
                type: 'category',
                show: false,
                data: plannedData
            }],
            yAxis: [{
                show: false,
                type: 'value',
                min: 0,
                max: Math.max(...plannedData)
            }],
            series: [
                {
                    type: 'bar',
                    barWidth: '15%',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    itemStyle: plannedStyle,
                    data: plannedData,
                    zlevel: 11
                },
                {
                    type: 'bar',
                    barWidth: '15%',
                    barGap: '-100%',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    itemStyle: completedStyle,
                    data: completedData,
                    zlevel: 11
                }
            ]
        };
    }


    getParamBarOptions(secondaryText) {
        let startHour = Math.trunc(this.props.startTime / 60);
        let endHour = Math.trunc(this.props.endTime / 60);

        let normalColor = "#26F",
            warningColor = "rgb(255, 125, 70)";
        
        let data = [];
        
        for(let i = 0; i < endHour-startHour; i++) {
            let value = Math.trunc(Math.random()*30 + 70);
            data.push({value, itemStyle: {color: value > 80 ? normalColor : warningColor}});
        }
        
        return {
            title: {
                left: 'center',
                bottom: 5,
                text: `${(data.map(e=>e.value).reduce((a,v)=>a+v) / data.length).toFixed(1)}%`,
                textStyle: {
                    color: "#ddd",
                    fontSize: 18,
                    fontWeight: 'bold',
                    fontFamily: 'Arial'
                },
                itemGap: 1,
                subtext: secondaryText,
                subtextStyle: {
                    color: '#ddd',
                    fontSize: 12,
                    fontFamily: 'Arial'
                }
            },

            animation: true,
            backgroundColor: 'rgb(30, 29, 33)',

            xAxis: [{
                type: 'category',
                show: false,
                data
            }],
            yAxis: [{
                show: false,
                type: 'value',
                min: 0,
                max: Math.max(...data)
            }],
            series: [
                {
                    type: 'bar',
                    barWidth: '15%',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    itemStyle: {
                        
                    },
                    data,
                    zlevel: 11
                }
            ]
        };
    }
}

const mapStateToProps = state => {
    let {servicesByHourMap} = state.map;
    return {
        servicesByHourMap,
        ...state.timer
    };
};

export default withRouter(connect(mapStateToProps, {timerStop})(DetailsSideBar));