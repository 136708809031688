import './MainView.css'

import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import TimeLine from '../TimeLine/TimeLine';
import TruckDetailsDashboard from "../TruckDetailsDashboard/TruckDetailsDashboard";

import {connect} from "react-redux";
import {
    fetchRoutes,
    showRouteDetails,
    showOverview,
    mapStateChanged,
    highlightRoute,
    timerInit, timerStart, timerStop, fetchVehicleCapacities
} from "../../Redux/actions";

import {InteractiveMap} from 'react-map-gl';
import DeckGL, {IconLayer} from 'deck.gl';
import {TripsLayer} from '@deck.gl/experimental-layers';

import {Route, Switch, withRouter} from "react-router-dom";

import pointIcon from './point.png';
import turf from "turf";

import mapboxgl from "mapbox-gl";

import classNames from 'classnames';

const MAPBOX_TOKEN = 'pk.eyJ1IjoibjFjMHNoIiwiYSI6ImNqOTcxZmM2OTAya3AzMnJ3ZHhrMWx5cnUifQ.AgEyh9xOy31f-h5LksaYRQ'; // eslint-disable-line

class MainView extends Component {
    constructor(props) {
        super(props);
        this.mapbox = undefined;
        this.labelLayerId = undefined;
        this.deckLayers = undefined;
        this.state = {
            vehiclesListScrolled: false,
            vehiclesListOverflowed: false,
            mapReady: false,
            deckRenderFunc: this._showTripsLayer,
            hoverPopup: null,
            globalViewState: {
                longitude: 7.4957,
                latitude: 51.5479,
                zoom: 9,
                pitch: 30,
                bearing: 0
            }
        }
    }
    
    componentDidMount() {
        if (this.props.location.pathname.includes("details")) {
            this.setState({deckRenderFunc: ()=>[]});
            this.props.timerStop();
        } else {
            this.setState({deckRenderFunc: this._showTripsLayer});
            this.props.timerStart();
        }
    }

    componentDidUpdate(pProps) {
        if (this.props.highlightRoute !== pProps.highlightRoute && this.state.mapReady) {
            let map = this.mapbox;
            let vehicleId = this.props.highlightRoute,
                vehicleRouteData = this.props.vehicleRouteMap.get(vehicleId);

            if (vehicleId !== -1) {

                if (map.getSource(vehicleId + "-route") === undefined) {
                    map.addSource(vehicleId + "-route", {
                        type: 'geojson',
                        data: vehicleRouteData.routeMultiLineString
                    });
                }

                map.addLayer({
                    "id": "highlightedRoute",
                    "type": "line",
                    "source": vehicleId + "-route",
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "rgb(41, 166, 240)",
                        "line-width": 4
                    }
                }, this.labelLayerId);
            } else {
                map.removeLayer("highlightedRoute")
            }
        }

        if (this.props.location.pathname !== pProps.location.pathname && this.state.mapReady) {
            let map = this.mapbox;
            if (this.props.location.pathname.includes("details")) {
                this.setState({
                    deckRenderFunc: ()=>[],
                    globalViewState: this.props.viewState
                });
                this.props.timerStop();
                
                let vehicleId = parseInt(this.props.match.params.vehicleId),
                    vehicleRouteData = this.props.vehicleRouteMap.get(vehicleId);

                this._addDetailsLayer(vehicleId, vehicleRouteData);

                setTimeout(() => {
                    map.easeTo(this._cameraForRoute(vehicleRouteData.routeMultiLineString))
                }, 400);

                setTimeout(() => {
                    this.props.mapStateChanged({
                        longitude: map.getCenter().lng,
                        latitude: map.getCenter().lat,
                        zoom: map.getZoom(),
                        pitch: map.getPitch(),
                        bearing: map.getBearing()
                    });
                    this.setState({deckRenderFunc: this._showWaypointsLayer});
                }, 1400);
            } else {
                this.setState({deckRenderFunc: ()=>[]});
                let {zoom, pitch, bearing, longitude, latitude} = this.state.globalViewState;

                this.mapbox.easeTo({
                    center: {lng: longitude, lat: latitude},
                    zoom,
                    pitch,
                    bearing,
                    duration: 1000
                });

                setTimeout(() => {
                    this.props.mapStateChanged({
                        longitude: this.mapbox.getCenter().lng,
                        latitude: this.mapbox.getCenter().lat,
                        zoom: this.mapbox.getZoom(),
                        pitch: this.mapbox.getPitch(),
                        bearing: this.mapbox.getBearing()
                    });
                    if(map.getLayer("routeDetails")) {
                        map.removeLayer("routeDetails")
                    }
                    this.setState({deckRenderFunc: this._showTripsLayer});
                    this.props.timerStart();
                }, 1000);
            }
        }

        this.updateVehiclesList();
    }

    _cameraForRoute = (routeMultiLineString) => {
        let bbx = turf.bbox(routeMultiLineString),
            bounds = [
                [bbx[0], bbx[1]],
                [bbx[2], bbx[3]]
            ];

        let cameraOptions = this.mapbox.cameraForBounds(bounds);
        cameraOptions.pitch = 200;
        cameraOptions.bearing = 45;
        cameraOptions.duration = 1000;
        return cameraOptions;
    };
    
    _onHover = (info) => {
        let {lngLat, picked, object} = info;

        if(this.state.hoverPopup !== null) {
            this.state.hoverPopup.remove();
            this.setState({hoverPopup: null})
        }
        
        if(picked) {
            let tw = object.properties.timeWindows; 
            let hoverPopup = new mapboxgl.Popup({closeOnClick: true, anchor: 'bottom'})
                .setLngLat(lngLat)
                .setHTML(`<div><h3>Position: (${lngLat[1].toFixed(2)}, ${lngLat[0].toFixed(2)})</h3><h4>Opening hours: ${this._convertMinutesToTwelveHours(tw[0])} - ${this._convertMinutesToTwelveHours(tw[1])}</h4></div>`)
                .addTo(this.mapbox);
            this.setState({hoverPopup});
        }
        console.log(info);
    };

    _showTripsLayer = ()=> {
        return [
            new TripsLayer({
                id: 'trips',
                data: this.props.vehicles,
                getPath: d => d.segments,
                getColor: [95, 174, 238],
                trailLength: 30,
                currentTime: this.props.currentTime
            })];
    };
    
    _showWaypointsLayer = ()=> {
        let activeVehicle = parseInt(this.props.match.params.vehicleId);

        if (!isNaN(activeVehicle)) {
            let data = this.props.vehicleRouteMap.get(activeVehicle).waypoints;
            return [
                new IconLayer({
                    id: 'waypoints',
                    getPosition: d => d.geometry.coordinates,
                    data,
                    pickable: true,
                    wrapLongitude: true,
                    iconAtlas: pointIcon,
                    iconMapping: {
                        "marker": {
                            "x": 0,
                            "y": 0,
                            "width": 80,
                            "height": 80,
                            "anchorY": 40,
                            "anchorX": 40
                        }},
                    onHover: this._onHover,
                    sizeScale: 1,
                    getIcon: d => 'marker',
                    getSize: 20
                })];
        }
    };

    updateVehiclesList() {
        let scrolled = !!(this.vehiclesBar.scrollLeft > 0);
        let overflowedWidth = this.vehiclesBar.scrollWidth - this.vehiclesBar.clientWidth - this.vehiclesBar.scrollLeft;
        let overflowed = !!(overflowedWidth > 0);
        if (this.state.vehiclesListScrolled !== scrolled || this.state.vehiclesListOverflowed !== overflowed) {
            this.setState({
                vehiclesListScrolled: scrolled,
                vehiclesListOverflowed: overflowed,
            })
        }
    }

    scrollVehiclesList = (e) => {
        this.vehiclesBar.scrollLeft += e.deltaY;
        this.updateVehiclesList();
    };

    render() {
        this.deckLayers = this.state.deckRenderFunc();
         
        let activeVehicle = parseInt(this.props.match.params.vehicleId);
        return (
            <Fragment>
                <main className={'main-wrapper'}>
                    <div className="vehicles-navbar-container">
                        <h3 className="title">Trucks</h3>
                        <div className={classNames({
                            "vehicles-navbar-wrapper": true,
                            "scrolled": this.state.vehiclesListScrolled,
                            "overflowed": this.state.vehiclesListOverflowed
                        })}>
                            <ul
                                ref={r => {
                                    this.vehiclesBar = r
                                }}
                                onWheel={this.scrollVehiclesList}
                                className="vehicles-navbar"
                            >
                                {this.props.vehicles.map(v =>
                                    <li key={v.vehicle}>
                                        <Link
                                            onMouseOver={() => this.props.highlight(v.vehicle)}
                                            onMouseOut={() => this.props.highlight(-1)}
                                            className={activeVehicle === v.vehicle ? 'active' : ''}
                                            to={activeVehicle === v.vehicle ? "/" : `/details/${v.vehicle}`}
                                        >{v.vehicle}
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <DeckGL
                        layers={this.deckLayers}
                        viewState={this.props.viewState}
                        controller={true}
                        onViewStateChange={(view) => {
                            this.props.mapStateChanged(view.viewState)
                        }}
                    >
                        <InteractiveMap
                            ref={map => {
                                if (map && !this.mapbox) {
                                    this.mapbox = window.map = map.getMap()
                                }
                            }}
                            reuseMaps={true}
                            mapStyle="mapbox://styles/mapbox/dark-v9"
                            preventStyleDiffing={true}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            onLoad={this._onMapLoad}
                        >
                        </InteractiveMap>
                    </DeckGL>
                    <Switch>
                        <Route exact path="/" component={TimeLine}/>
                        <Route path="/details/:vehicleId([0-9]*)" component={TruckDetailsDashboard}/>
                    </Switch>
                </main>
            </Fragment>
        );
    }
    
    _addDetailsLayer = (vehicleId, vehicleRouteData) => {
        let map = this.mapbox;

        if (map.getSource(`${vehicleId}-route`) === undefined) {
            map.addSource(`${vehicleId}-route`, {
                type: 'geojson',
                data: vehicleRouteData.routeMultiLineString
            });
        }
        if(map.getLayer("routeDetails")) {
            map.removeLayer("routeDetails")
        }
        map.addLayer({
            "id": "routeDetails",
            "type": "line",
            "source": `${vehicleId}-route`,
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "rgb(41, 166, 240)",
                "line-width": 4
            }
        }, this.labelLayerId);
    }; 

    _onMapLoad = (event) => {
        let map = event.target,
            layers = map.getStyle().layers;

        for (let i = 0; i < layers.length; i++) {
            if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                this.labelLayerId = layers[i].id;
                break;
            }
        }

        map.addLayer({
            'id': '3d-buildings',
            'source': 'composite',
            'source-layer': 'building',
            'filter': ['==', 'extrude', 'true'],
            'type': 'fill-extrusion',
            'minzoom': 15,
            'paint': {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': ["interpolate", ["linear"], ["zoom"],
                    15, 0,
                    15.05, ["get", "height"]
                ],
                'fill-extrusion-base': ["interpolate", ["linear"], ["zoom"],
                    15, 0,
                    15.05, ["get", "min_height"]
                ],
                'fill-extrusion-opacity': 1
            }
        }, this.labelLayerId);

        map.loadImage(pointIcon, (error, image) => {
            if (error) {
                throw error;   
            }
            map.addImage("waypoint", image);
        });

        if (this.props.location.pathname.includes("details")) {
            let vehicleId = parseInt(this.props.match.params.vehicleId),
                vehicleRouteData = this.props.vehicleRouteMap.get(vehicleId);
            
            this._addDetailsLayer(vehicleId, vehicleRouteData);
            
            map.flyTo(this._cameraForRoute(vehicleRouteData.routeMultiLineString));
            
            setTimeout(() => {
                this.props.mapStateChanged({
                    longitude: map.getCenter().lng,
                    latitude: map.getCenter().lat,
                    zoom: map.getZoom(),
                    pitch: map.getPitch(),
                    bearing: map.getBearing()
                });
                this.setState({deckRenderFunc: this._showWaypointsLayer});
            }, 200);
        }
        
        this.setState({mapReady: true})
    };

    _convertMinutesToTwelveHours(currentTime) {
        let hours = Math.trunc(currentTime / 60),
            minutes = Math.trunc(currentTime % 60);
        return `${hours > 12 ? (hours - 12) : hours}:${minutes > 9 ? minutes : ("0" + minutes)} ${hours > 12 ? "PM" : "AM"}`
    }
}

const mapStateToProps = state => {
    return {
        ...state.map,
        ...state.timer
    };
};

export default withRouter(connect(mapStateToProps, {
    mapStateChanged,
    timerInit,
    timerStart,
    timerStop,
    highlight: highlightRoute
})(MainView));
