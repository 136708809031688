import ActionType from "../actionTypes";

import turf from 'turf';

const initialState = {
    isRoutesFetched: false,
    isFetchingRoutes: false,
    isCapacitiesFetched: false,
    isFetchingVehicleCapacities: false,
    vehicleDataMap: new Map(),
    vehicleRouteMap: new Map(),
    servicesByHourMap: new Map(),
    totalServices: 0,
    plannedServices: [],
    vehicles: [],
    maxTime: 9999,
    minTime: 0,
    viewState:  {
        longitude: 7.4957, 
        latitude: 51.5479,
        zoom: 9,
        pitch: 30,
        bearing: 0
    },
    highlightRoute: -1
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionType.HIGHLIGHT_ROUTE:
            return {
                ...state,
                highlightRoute: action.vehicleId
            };
        case ActionType.MAP_STATE_CHANGED:
            return {
                ...state,
                viewState: action.viewState
            };
        case ActionType.FETCH_ROUTES_REQUEST:
            return {
                ...state,
                isFetchingRoutes: true
            };
        case ActionType.FETCH_ROUTES_SUCCESS:
         /**   Current CSV structure:
                1: "ScenarioName"
                2: "Vehicle_Id"
                5: "ShipmentId_Origin"
                6: "Lat_Origin"
                7: "Long_Origin"
                8: "SequenceNumber_Origin"
                9: "ShipmentId_Destination"
                10: "Lat_Destination"
                11: "Long_Destination"
                12: "DepartureTime_Origin"
                13: "TimeWindows_Origin"
                14: "Duration_Travel"
                15: "DurationService_Destination"
                16: "WeightUtilization_Origin"
                17: "VolumeUtilization_Origin"
                18: "LoadingMeterUtilization_Origin"
                19: "PalletsUtilization_Origin"
                22: "waypoints_path"
            */
            
            let vehicleDataMap = new Map();
            let vehicleRouteMap = new Map();
            let servicesByHourMap = new Map();
            
            let minTime = Number.MAX_VALUE;
            let maxTime = Number.MIN_VALUE;
            
            action.data.data.forEach((el) => {
                let vehicleId = parseInt(el[2]),
                    sequenceNumber = parseInt(el[8]),
                    shipmentIdOrigin = el[5],
                    shipmentIdDestination = el[9],
                    pointProperties = {properties: {sequence: sequenceNumber}},
                    originPoint = Object.assign(turf.point([parseFloat(el[7]), parseFloat(el[6])]), pointProperties), //Lng Lat
                    destinationPoint = Object.assign(turf.point([parseFloat(el[11]), parseFloat(el[10])]), pointProperties), //Lng Lat
                    departureTime = parseInt(el[12]),
                    timeWindows = el[13] ? el[13].replace(/\[(.*?)]/, "$1").split(" ").map(e=>parseInt(e)) : "",
                    travelDuration = parseInt(el[14]),
                    serviceDuration = parseInt(el[15]),
                    weightUtilization = parseFloat(el[16]),
                    volumeUtilization = parseFloat(el[17]),
                    loadingMeterUtilization = parseFloat(el[18]),
                    palletsUtilization = parseFloat(el[19]);
                
                if(isNaN(vehicleId))
                    return;
                
                originPoint.properties.timeWindows = timeWindows;
                
                let totalDashTime = departureTime + travelDuration + serviceDuration;
                let dashHour = Math.trunc(totalDashTime / 60);
                
                let {total, byVehicles } = servicesByHourMap.get(dashHour) || servicesByHourMap.set(dashHour, {total: 0, byVehicles: new Map()}).get(dashHour);
                
                byVehicles.set(vehicleId, (byVehicles.get(vehicleId) || 0) +1);
                
                servicesByHourMap.set(dashHour, {
                    total: total + 1,
                    byVehicles
                });
                
                minTime = departureTime < minTime ? departureTime : minTime;
                maxTime = totalDashTime > maxTime ? totalDashTime : maxTime;
                
                let shipmentType, 
                    splitResult = shipmentIdDestination.split("_");
                
                shipmentType = splitResult.length > 1? splitResult[1] === "1" ? "Delivery" : "Pickup" : splitResult[0];
                
                let newVehicleRoute = {
                    waypoints: [],
                    routeMultiLineString: [],
                    routeArray: [],
                    routeArrayMerged: []
                };
                
                let vehicleRoute = vehicleRouteMap.get(vehicleId) || vehicleRouteMap.set(vehicleId, newVehicleRoute).get(vehicleId);
                
                if(!vehicleRoute.waypoints.find(e => {
                    let coords1 = e.geometry.coordinates;
                    let coords2 = originPoint.geometry.coordinates;
                    return coords1[0] === coords2[0] && coords1[1] === coords2[1];
                })) {
                    vehicleRoute.waypoints.push(originPoint);
                }
                
                if(el[22]) {
                    let dash = JSON.parse("[" + el[22] + "]")
                        .map(e => [e[1], e[0]]);
                    vehicleRoute.routeArray.push(dash);
                    
                    let servicePoints = [];
                    for(let i = 0; i < serviceDuration; i++) {
                        servicePoints.push(destinationPoint.geometry.coordinates.concat(departureTime + travelDuration + i));
                    }
                    
                    let dashWithTimestamps = dash.map((e, i) => [e[0], e[1], departureTime + (travelDuration / dash.length) * i]);
                    vehicleRoute.routeArrayMerged = vehicleRoute.routeArrayMerged
                        .concat(dashWithTimestamps)
                        .concat(servicePoints);
                }
                
                let vehicleData = vehicleDataMap.get(vehicleId) || vehicleDataMap.set(vehicleId, []).get(vehicleId);
                
                let vehicleDataDash = {
                    vehicleId,
                    sequenceNumber,
                    shipmentIdOrigin,
                    shipmentIdDestination,

                    originPoint,
                    destinationPoint,
                    
                    departureTime,
                    timeWindows,
                    travelDuration,
                    serviceDuration,
                    dashHour,
                    
                    weightUtilization,
                    volumeUtilization,
                    loadingMeterUtilization,
                    palletsUtilization,
                    
                    shipmentType
                };
                
                vehicleData.push(vehicleDataDash);
            });
            
            for(let vehicleRoute of vehicleRouteMap.values()) {
                vehicleRoute.routeMultiLineString = turf.multiLineString(vehicleRoute.routeArray);
            }
            
            let vehicles = [];
            vehicleRouteMap.forEach((v, k) => vehicles.push({vehicle: k, segments: v.routeArrayMerged}));
            
            let totalServices = 0;
            let plannedServices = [];
            
            servicesByHourMap.forEach(value=> {
                totalServices += value.total;
                plannedServices.push(value.total)
            });
            
            maxTime += 60;
            
            return {
                ...state,
                isFetchingRoutes: false,
                isRoutesFetched: true,
                vehicleDataMap,
                vehicleRouteMap,
                servicesByHourMap,
                totalServices,
                plannedServices,
                vehicles,
                maxTime,
                minTime
            };
        case ActionType.FETCH_ROUTES_FAILURE: 
            return {
                ...state,
                isFetchingRoutes: false,
                error: action.error
            };

        case ActionType.FETCH_VEHICLE_CAPACITIES_REQUEST:
            return {
                ...state,
                isFetchingVehicleCapacities: true
            };
        case ActionType.FETCH_VEHICLE_CAPACITIES_SUCCESS:
            return {
                ...state,
                vehicleCapacities: action.data,
                isCapacitiesFetched: true,
                isFetchingVehicleCapacities: false
            };
        case ActionType.FETCH_VEHICLE_CAPACITIES_FAILURE:
            return {
                ...state,
                isFetchingVehicleCapacities: false,
                error: action.error
            };
        default:
            return state;
    }
}