import ActionType from "./actionTypes";

import Papa from 'papaparse';

export function highlightRoute(vehicleId) {
    return {
        type: ActionType.HIGHLIGHT_ROUTE,
        vehicleId
    }
}

export function mapStateChanged(viewState) {
    return {
        type: ActionType.MAP_STATE_CHANGED,
        viewState
    }
}

export function mapPointSelected(lngLat) {
    return {
        type: ActionType.MAP_POINT_SELECTED,
        lngLat
    }
}

export function showOverview() {
    return {
        type: ActionType.SHOW_OVERVIEW
    }
}


export function showRouteDetails() {
    return {
        type: ActionType.SHOW_ROUTE_DETAILS,
    }
}

export function timerChangeSpeed(delta) {
    return {
        type: ActionType.TIMER_CHANGE_SPEED,
        delta
    }
}


export function timerTick() {
    return {
        type: ActionType.TIMER_TICK
    }
}

export function timerStart() {
    return {
        type: ActionType.TIMER_START
    }
}

export function timerInit(startTime, endTime) {
    return {
        type: ActionType.TIMER_INIT,
        currentTime: startTime,
        startTime,
        endTime
    }
}

export function timerReset() {
    return {
        type: ActionType.TIMER_RESET
    }
}

export function timerStop() {
    return {
        type: ActionType.TIMER_STOP
    }
}

export function fetchRoutesRequest() {
    return {
        type: ActionType.FETCH_ROUTES_REQUEST
    }
}


export function fetchRoutesSuccess(data) {
    return {
        type: ActionType.FETCH_ROUTES_SUCCESS,
        data
    }
}

export function fetchRoutesFailure(error) {
    return {
        type: ActionType.FETCH_ROUTES_FAILURE,
        error
    }
}

export function fetchRoutes() {
    return function(dispatch) {
        dispatch(fetchRoutesRequest());
        
        return fetch('/assets/data/RoutesDataFull.csv')
            .then(response => response.text(), error => console.log('Some error happened:', error))
            .then(data => {dispatch(fetchRoutesSuccess(Papa.parse(data)))})
            .catch(error => dispatch(fetchRoutesFailure(error)))
    }
}

export function fetchVehicleCapacitiesRequest() {
    return {
        type: ActionType.FETCH_VEHICLE_CAPACITIES_REQUEST,
        isFetchingVehicleCapacities: true,
        isCapacitiesFetched: false
    }
}


export function fetchVehicleCapacitiesSuccess(data) {
    return {
        type: ActionType.FETCH_VEHICLE_CAPACITIES_SUCCESS,
        isFetchingVehicleCapacities: false,
        isCapacitiesFetched: true,
        data
    }
}

export function fetchVehiclesCapacityFailure(error) {
    return {
        type: ActionType.FETCH_VEHICLE_CAPACITIES_FAILURE,
        isFetchingVehicleCapacities: false,
        isCapacitiesFetched: false,
        error
    }
}

export function fetchVehicleCapacities() {
    return function(dispatch) {
        dispatch(fetchVehicleCapacitiesRequest());

        return fetch('/assets/data/vehicles-capacity.json')
            .then(response => response.json(), error => console.log('Some error happened:', error))
            .then(data => {dispatch(fetchVehicleCapacitiesSuccess(data))})
            .catch(error => dispatch(fetchVehiclesCapacityFailure(error)))
    }
}

export function fetchData() {
    return function(dispatch) {
        
    }
}