import './TruckDetailsDashboard.css'

import React, {Component} from 'react'

import {connect} from "react-redux";
import {timerTick, timerStart, timerStop} from "../../Redux/actions";
import posed from "react-pose";
import {withRouter} from "react-router-dom";

const Widget = posed.section({
    visible: {
        y: 0,
        opacity: 1
    },
    hidden: {
        y:  20,
        opacity: 0
    }
});

class TruckDetailsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        setTimeout(()=>this.setState({isVisible: true}), 1300)
    }

    render() {
        let { isVisible } = this.state;
        
        let vehicleId = parseInt(this.props.match.params.vehicleId),
            vehicleData = this.props.vehicleDataMap.get(vehicleId);
        let start = this.props.startTime,
            end = this.props.endTime;
        let ppm = 1024 / (end-start); //Points Per Minute
        
        let drivingPath = "",
            pickupPath = "",
            deliveryPath = "";
        
        // debugger;
        vehicleData.forEach(e=>{
            let departure = e.departureTime - start,
                travel = e.travelDuration,
                service = e.serviceDuration;
            
            drivingPath += `M${(departure) * ppm} 2.5 h${travel * ppm}`;
            
            switch(e.shipmentType) {
                case "Pickup":
                    pickupPath += `M${(departure + travel) * ppm} 2.5 h${service * ppm}`;
                    break;
                case "Delivery":
                    deliveryPath += `M${(departure + travel) * ppm} 2.5 h${service * ppm}`;
                    break;
                case "Break":
                    break;
                case "Depot":
                    drivingPath += `M${(departure + travel) * ppm} 2.5 h${service * ppm}`;
                    break;
                default:
            }
            
            // e.shipmentType: "Pickup"
            // e.departureTime: 420
            // e.travelDuration: 7
            // e.serviceDuration: 10
        });
        
        console.log();
        
        return (
            <Widget className="truck-details-dashboard-panel" pose={isVisible ? "visible" : "hidden"}>
                <div className="truck-details-dashboard-wrapper">
                    <ul className="legend">
                        <li className="gray">Driving</li>
                        <li className="blue">Pickup</li>
                        <li className="green">Delivery</li>
                        {/*<li className="orange">Waiting</li>*/}
                    </ul>
                    <div className="plan-profile-wrapper">
                        <svg className="plan-profile" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 5">
                            <path id="svg-backgroung" stroke="#43454F" strokeWidth="1" d="M0 2.5 H1024"/>
                            
                            <path id="svg-driving" stroke="#43454F" strokeWidth="5" d={drivingPath}/>
                            <path id="svg-pickup" stroke="#2FAFF2" strokeWidth="5" d={pickupPath}/>
                            {/*<path id="svg-waiting" stroke="#FF7D46" strokeWidth="5" d="M84.2 5V0h15v5h-15zm170 0V0h15v5h-15zm170 0V0h10.3v5h-10.3zM608 5V0h15v5h-15zm170 0V0h15v5h-15zm170 0V0h15v5h-15z"/>*/}
                            <path id="svg-delivery" stroke="#46CA77" strokeWidth="5" d={deliveryPath}/>
                        </svg>
                        <div className="scale">
                            <b><time>7 AM</time></b>
                            <b><time>8 AM</time></b>
                            <b><time>9 AM</time></b>
                            <b><time>10 AM</time></b>
                            <b><time>11 AM</time></b>
                            <b><time>12 AM</time></b>
                            <b><time>1 PM</time></b>
                            <b><time>2 PM</time></b>
                            <b><time>3 PM</time></b>
                            <b><time>4 PM</time></b>
                            <b><time>5 PM</time></b>
                            <b><time>6 PM</time></b>
                        </div>
                    </div>
                </div>
                <ul className="kpi-wrapper">
                    <li className="kpi-stops">
                        <p className="value">{vehicleData.length}</p>
                        <p className="label">Stops planned</p>
                    </li>
                    <li className="kpi-service">
                        <p className="value">{this.minutesToHours(vehicleData.map(e=>e.serviceDuration).reduce((a,v)=>a+v))}</p>
                        <p className="label">Service time</p>
                    </li>
                    <li className="kpi-driving">
                        <p className="value">{this.minutesToHours(vehicleData.map(e=>e.travelDuration).reduce((a,v)=>a+v))}</p>
                        <p className="label">Driving time</p>
                    </li>
                    {/*<li className="kpi-waiting">*/}
                        {/*<p className="value">8:00</p>*/}
                        {/*<p className="label">Waiting time</p>*/}
                    {/*</li>*/}
                    <li className="kpi-break">
                        <p className="value">0:45</p>
                        <p className="label">Break time</p>
                    </li>
                </ul>
            </Widget>
        );
    }

    minutesToHours(timeInMinutes) {
        let hours = Math.trunc(timeInMinutes / 60),
            minutes = Math.trunc(timeInMinutes % 60);
        return `${hours}:${minutes > 9 ? minutes : ("0" + minutes)}`
    }
}

function mapDispatchToProps(state) {
    let {startTime, currentTime, endTime, timerId} = state.timer;
    return {
        vehicleDataMap: state.map.vehicleDataMap,
        startTime,
        currentTime,
        endTime,
        timerId
    }

}

export default withRouter(connect(mapDispatchToProps, {timerStart, timerStop, timerTick})(TruckDetailsDashboard));
