import './SideBar.css';

import React, {Component} from 'react';
import ReactEcharts from 'echarts-for-react';

import {connect} from "react-redux";
import {highlightRoute} from "../../Redux/actions"

import logo from './assets/logo.svg';

import posed from 'react-pose';

const Container = posed.aside({
    open: {
        delayChidren: 1200,
        staggerChildren: 150
    },
    closed: {
        delay: 300
    }
});

const Widget = posed.section({
    open: { y: 0, opacity: 1 },
    closed: { y: 20, opacity: 0 }
});



class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {
        setTimeout(()=>this.setState({isOpen: true}), 1000)
    }

    render() {

        let rowsWithingTimeframe = [];
        let waiting = 0;
        let driving = 0;
        let pickup = 0;
        let delivery = 0;

        let currentTime = this.props.currentTime;
        this.props.vehicleDataMap.forEach((v) => {
            let filteredRows = v.filter(e => e.departureTime <= currentTime && currentTime < e.departureTime + e.travelDuration + e.serviceDuration);

            if(filteredRows.length) {
                let row = filteredRows[0];
                rowsWithingTimeframe.push(row);

                let departure = row.departureTime;
                let travelFinished = departure + row.travelDuration;
                let serviceFinished = travelFinished + row.serviceDuration;

                if(departure <= currentTime && currentTime < travelFinished) {
                    driving++;
                }
                else if (travelFinished <= currentTime && currentTime < serviceFinished) {
                    if (row.shipmentType === "Pickup") {
                        pickup++;
                    } else if (row.shipmentType === "Delivery") {
                        delivery++;
                    }
                }
            }
        });

        let avgUtilization = 0;
        if(this.props.vehicleCapacities && rowsWithingTimeframe.length > 0) {
            avgUtilization = rowsWithingTimeframe.map(e=>e.loadingMeterUtilization/this.props.vehicleCapacities[e.vehicleId].loadingMeter)
                .reduce((a, v)=>a+v) / rowsWithingTimeframe.length
        } 
        let value = avgUtilization * 100; 
        let maskPath = "M5 5h130v" + (1-avgUtilization) * 102 + "H5z";

        const { isOpen } = this.state;
        return (
            <Container className="SideBar" pose={isOpen ? 'open' : 'closed'}>
                <header><img src={logo} className="logo" alt={''}/></header>

                <Widget className="widget">
                    <ReactEcharts
                        style={{height: '100%', width: '100%'}}
                        option={SideBar.getPieOptions(waiting, driving, pickup, delivery, 51)}
                        notMerge={true}
                        lazyUpdate={true}
                    />
                </Widget>
                <Widget className="widget">
                    <ReactEcharts
                        style={{height: '100%', width: '100%'}}
                        option={SideBar.getBarOptions(this.props.currentTime, this.props.totalServices, this.props.plannedServices, this.props.servicesByHourMap)}
                    />
                </Widget>
                <Widget className="widget">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink" width="215" height="140" viewBox="0 0 215 140">
                        <defs>
                            <path id="a" d="M132.7 107H51.3c-4-3.8-9.2-6-14.9-6-6.1 0-11.6 2.2-15.6 6H9a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h122a4 4 0 0 1 4 4v94.5l-2.3 3.5z"/>
                            <g id="w">
                                <ellipse cx="15.4" cy="15.3" rx="14.9" ry="14.8"/>
                                <ellipse cx="15.4" cy="15.3" rx="8.8" ry="8.7"/>
                                <ellipse cx="15.4" cy="15.3" rx="1" ry="1"/>
                                <ellipse cx="15.4" cy="10.7" rx="1" ry="1"/>
                                <ellipse cx="19.4" cy="13" rx="1" ry="1"/>
                                <ellipse cx="19.4" cy="17.6" rx="1" ry="1"/>
                                <ellipse cx="15.4" cy="19.9" rx="1" ry="1"/>
                                <ellipse cx="11.4" cy="17.6" rx="1" ry="1"/>
                                <ellipse cx="11.4" cy="13" rx="1" ry="1"/>
                            </g>
                        </defs>
                        <g fill="none" opacity="0.5" stroke="#BCC2C9" fillRule="evenodd">
                            <use xlinkHref="#w" transform="translate(146 108.5)"/>
                            <use xlinkHref="#w" transform="translate(21 108.5)"/>
                            <path stroke="#BCC2C9" strokeLinecap="round" strokeLinejoin="round" d="M49.5 111.5c-3.3-3.7-8-6-13-6-5.5 0-10.4 2.3-13.7 6H6.5a6 6 0 0 1-6-6v-99a6 6 0 0 1 6-6h127a6 6 0 0 1 6 6v97.3l-5.2 7.7H49.5z"/>
                        </g>
                        <path fill="#BCC2C9" opacity="0.5" fillRule="nonzero" d="M147 103l-7 10v17h-1v-17l7-11V48.5c0-.3.2-.5.5-.5H170c23.4 0 38 21.8 38 47.7l-.1.4-2.9 3v8.9h8.5c.3 0 .5.2.5.5v10c0 .3-.2.5-.5.5H184a.5.5 0 0 1-.5-.5c0-7.3-5.4-15.5-11.5-15.5h-25zm0-30v29h25c6.7 0 12.3 8.3 12.5 16H204V99l.1-.4 2.9-3.1c0-1.9-.2-3.7-.4-5.5H198c-3 0-5-2.2-8-6.5l-1.7-2.6c-3.9-6-6.6-7.9-11.3-7.9h-30zm0-1h30c5.1 0 8 2.1 12.2 8.3l1.7 2.7c2.7 4 4.5 6 7.1 6h8.5c-2.4-22.2-15.8-40-36.5-40h-23v23zm58 46h8v-9h-8v9zm6-12a.5.5 0 0 1-1 0v-7.3a.5.5 0 0 1 1 0v7.3zm-58-20a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zm33-27a.5.5 0 0 1 1 0v10a.5.5 0 0 1-1 0V59zM111 121v-3h1v3h23.5v1h-81v-1H71v-3h1v3h19v-3h1v3zM13 119H4v-1h8l-2-2 1-1 7 7-1 1z"/>
                        <mask id="b" fill="#fff">
                            <use xlinkHref="#a"/>
                        </mask>
                        <path fill="#2FAFF2" d="M5 5h130v102H5z" mask="url(#b)"/>
                        <path id="trackFill" fill="#4A505B" d={maskPath} mask="url(#b)"/>
                        <text x="33%" y="60" fill="black" textAnchor="middle" style={{font: "25px sans-serif"}}>{value.toFixed(1)}%</text>
                        <text x="33%" y="80" fill="black" textAnchor="middle" style={{font: "12px sans-serif"}}>Avg. utilization</text>
                    </svg>
                </Widget>
            </Container>
        );
    }

    static getPieOptions(waiting, driving, pickup, delivery, vehicles) {
        return {
            title: {
                text: vehicles,
                top: '65',
                left: 'center',
                itemGap: 3,
                textStyle: { color: '#fff' },
                subtext: "trucks",
                subtextStyle: { color: '#fff' },
            },

            legend: {
                selectedMode: false,
                bottom: 0,
                textStyle: {
                    color: '#fff',
                    fontFamily: 'Arial'
                }
            },
            backgroundColor: 'rgb(30, 29, 33)',

            series: [
                {
                    type: 'pie',
                    radius: ['55%', '75%'],
                    center: ['50%', '40%'],
                    avoidLabelOverlap: false,
                    hoverAnimation: false,
                    label: {
                        position: 'inside',
                        formatter: '{c}',
                        textStyle: {
                            color: '#FFF',
                            fontSize: 14,
                            fontFamily: 'Arial'
                        }
                    },
                    itemStyle: {
                        borderWidth: 2, 
                        borderColor: 'rgb(30, 29, 33)'
                    },
                    data: [
                        //{value: waiting, name: 'Waiting', itemStyle: {color: 'rgb(239, 131, 84)'}},
                        {value: driving, name: 'Driving', itemStyle: {color: 'rgb(59, 60, 69)'}},
                        {value: pickup, name: 'Pickup', itemStyle: {color: 'rgb(41, 166, 240)'}},
                        {value: delivery, name: 'Delivery', itemStyle: {color: 'rgb(62, 195, 108)'}}
                    ]
                }
            ]
        };
    }
    
    static getBarOptions(currentTime, totalServices, plannedServices) {
        const completedColor = 'rgb(41, 164, 237)';
        const completedStyle = {
            barBorderRadius: [3, 3, 0, 0],
            color: completedColor,
            shadowBlur: 1,
            shadowColor: completedColor
        };
        const plannedColor = 'rgb(128, 128, 128)';
        const plannedStyle = {
            barBorderRadius: [3, 3, 0, 0],
            color: plannedColor,
            shadowBlur: 1,
            shadowColor: plannedColor
        };
        
        const startHour = 7;
        const plannedData = plannedServices;
        const maxServices = Math.max(...plannedData);
        const currentHour = Math.trunc(currentTime / 60) - startHour;
        const currentMinutes = currentTime % 60;
        const completedData = plannedData.map((e, index)=>index < currentHour ? e : index === currentHour ? Math.trunc(plannedData[currentHour] * currentMinutes / 60) : 0);

        let completedServices = completedData.length > 0 ? completedData.reduce((val, acc)=>val+acc) : 0;
        

        return {
            title: {
                left: 'center',
                bottom: 5,
                text: `{completed|${completedServices}}{planned| / ${totalServices}}`,
                textStyle: {
                    rich: {
                        completed: {
                            color: completedColor,
                            fontSize: 18,
                            fontWeight: 'bold',
                            fontFamily: 'Arial'
                        },
                        planned: {
                            color: plannedColor,
                            fontSize: 18,
                            fontWeight: 'bold',
                            fontFamily: 'Arial'
                        }
                    }
                },
                itemGap: 1,
                subtext: 'Completed services',
                subtextStyle: {
                    color: '#ddd',
                    fontSize: 18,
                    fontFamily: 'Arial'
                }
            },

            animation: false,
            backgroundColor: 'rgb(30, 29, 33)',

            color: ['#3398DB'],
            xAxis: [{
                type: 'category',
                show: false,
                data: completedData
            }],
            yAxis: [{
                show: false,
                type: 'value',
                min: 0,
                max: maxServices
            }],
            series: [
                {
                    type: 'bar',
                    barWidth: '15%',
                    barGap: '-100%',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    itemStyle: plannedStyle,
                    data: plannedData,
                    zlevel: 11
                },
                {
                    type: 'bar',
                    barWidth: '15%',
                    barGap: '-100%',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    itemStyle: completedStyle,
                    data: completedData,
                    zlevel: 11
                }
            ]
        };       
    }
}

const mapStateToProps = state => {
    return {
        ...state.map,
        currentTime: state.timer.currentTime
    };
};

export default connect(mapStateToProps, {highlightRoute})(SideBar);
