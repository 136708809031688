import './App.css';

import React, {Component, Fragment} from 'react';

import {Route, Switch, withRouter} from "react-router-dom";

import SideBar from './Components/SideBar/SideBar';
import DetailsSideBar from './Components/DetailsSideBar/DetailsSideBar'
import MainView from './Components/MainView/MainView'
import {fetchRoutes, fetchVehicleCapacities, timerInit} from "./Redux/actions";
import connect from "react-redux/es/connect/connect";


class App extends Component {
    componentDidMount() {
        this.props.fetchRoutes();
        this.props.fetchVehicleCapacities();
    }

    componentDidUpdate() {
        if (this.props.isRoutesFetched && this.props.isCapacitiesFetched && !this.props.isTimerInitialized) {
            // debugger;
            this.props.timerInit(this.props.minTime, this.props.maxTime);
        }
    }

    render() {
        if (this.props.isRoutesFetched && this.props.isCapacitiesFetched)
            return (
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={SideBar}/>
                        <Route path="/details/:vehicleId([0-9]*)" component={DetailsSideBar}/>
                    </Switch>
                    <Switch>
                        <Route exact path="/" component={MainView}/>
                        <Route path="/details/:vehicleId([0-9]*)" component={MainView}/>
                    </Switch>

                </div>
            );
        else
            return (
                <Fragment>
                    <div style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '30px',
                        backgroundColor: 'var(--black)'
                    }}>Loading...
                    </div>
                </Fragment>);
    }
}

function mapStateToProps(state) {
    let {
        isRoutesFetched,
        isCapacitiesFetched,
        minTime,
        maxTime
    } = state.map;
    
    return {
        isRoutesFetched,
        isCapacitiesFetched,
        isTimerInitialized: state.timer.isTimerInitialized,
        minTime,
        maxTime
    }
}

export default withRouter(connect(mapStateToProps, {fetchRoutes, fetchVehicleCapacities, timerInit})(App));
